const faqs = [
  {
    question: "Requisitos para el visado de estudio para argentinos en España",
    answer: (
      <div>
        <p>- Pasaporte válido: Tu pasaporte debe ser válido por al menos seis meses después de la fecha prevista de salida de España.</p><br/>
        <p>- Carta de admisión de la universidad o institución educativa: Debes presentar una carta de aceptación de la universidad o institución educativa en la que se detallen tus planes de estudio.</p><br/>
        <p>- Prueba de recursos financieros: Es importante demostrar que tenés suficientes recursos financieros para cubrir tus gastos mientras estudias en España. Esto puede incluir estados de cuenta bancarios, cartas de garantía financiera de tus padres o tutores, o cualquier otra documentación que demuestre tu solvencia financiera.</p><br/>
        <p>- Certificado médico: Es posible que necesites presentar un certificado médico que demuestre que no padeces ninguna enfermedad contagiosa.</p><br/>
        <p>- Seguro médico: También debes contar con un seguro médico que cubra tus gastos médicos en España.</p> <br/>
        <p>Una vez que tengas tu visa de estudiante, podrás ingresar a España y comenzar tus estudios. </p>
      </div>
    ),
  },
  {
    question: "¿Puedo trabajar en España con visa de estudiante?",
    answer: (
      <div>
        <p>Es importante que sepas que la visa de estudiante tiene ciertas restricciones.  Por ejemplo, no puedes trabajar a tiempo completo mientras estudias en España, y es posible que necesites solicitar una prórroga de tu visa si tu programa de estudios se extiende más allá del período de validez de tu visa.
 </p> 

      </div>
    ),
  },
  {
    question: "¿Es obligatorio contar con un seguro de viaje para entrar a España?",
    answer: (
      <div>
        <p>
        Para obtener una visa de estudiante para estudiar en España, debés contar con un seguro médico debido a diferentes razones: 
        </p> <br/>
        <p>- Requistio legal.</p>
        <p>- Acceso a atención médica.</p>
        <p>- Protección financiera.</p>
        <p>- Seguridad y tranquilidad.</p>
      </div>
    ),
  },
];

export default faqs;
